@charset "UTF-8";
.main-section {
  min-width: 1000px; }

.off-canvas-list,
.off-canvas-list * {
  display: none !important; }

/*@media	only screen and (min-width: 60.063em),
		only screen and (min-width: 30.063em) and (max-width: 40em){*/
.footerAddress {
  text-align: left; }

.footerAddress p {
  display: block;
  width: 464px;
  margin: 0 auto; }

.footerAddress .name {
  float: left;
  width: 210px;
  height: 90px;
  border-bottom: 0;
  border-right: solid 2px #FFF;
  text-indent: -999px;
  margin-right: 16px; }

.footerAddress .adress {
  padding-top: 8px; }

/*}*/
/* Mobile Paysage */
/*@media only screen and (min-width: 30.063em) {*/
.tab-bar .menuRecherche {
  width: 277px; }

.tab-bar .menuRecherche input[type=text] {
  width: 240px; }

/*}*/
/* Tablette / PC */
/*@media only screen and (min-width: 40.063em) {*/
.header .bt_accueil {
  width: 320px;
  margin: 9px 0 9px 22px; }

/*////////////////////////////////////////////////////////////////////////////*/
/*///////////////////////////////// RECHERCHE ////////////////////////////////*/
/*////////////////////////////////////////////////////////////////////////////*/
.header .btContact {
  width: 120px;
  height: 82px;
  display: block;
  float: left;
  margin-left: 84px;
  background-image: url("../images/bt_contact.png");
  background-position: 0 0;
  background-repeat: no-repeat;
  text-indent: -9999px;
  outline: none; }

.header .menuRecherche {
  float: right;
  height: 40px;
  /*padding-right: 32px;*/
  padding: 4px 0; }

.header .menuSocial {
  display: block;
  clear: right;
  float: right;
  padding: 4px 0; }

.header .menuSocial ul {
  list-style: none;
  padding: 0;
  margin: 0; }

.header .menuSocial ul > li > a {
  display: block;
  width: 32px;
  height: 32px;
  text-indent: -9999px;
  background-image: url("../images/socialIcon_32.png");
  background-repeat: no-repeat;
  -webkit-transition: background-color 0.25s;
  -moz-transition: background-color 0.25s;
  -o-transition: background-color 0.25s;
  transition: background-color 0.25s; }

.header .menuSocial ul > li > a:hover {
  background-color: #FFF; }

.header .menuSocial ul > li > a.btFacebook {
  background-position: 0px 0px; }

.header .menuSocial ul > li > a.btFacebook:hover {
  background-position: 0px -64px; }

/****************************** MAINMENU **************************************/
.mainMenu ul,
.mainMenu li {
  padding: 0;
  margin: 0;
  list-style: none; }

.mainMenu ul.menu {
  float: right; }

.mainMenu .bt {
  display: block;
  background-color: #892b61;
  color: #FFF;
  font-size: 0.9em;
  padding: 0 16px;
  font-weight: 600;
  text-align: center; }

.mainMenu ul.menu > li {
  display: block;
  float: left;
  margin: 0 2px;
  position: relative; }

.mainMenu ul.menu > li > .bt {
  height: 32px;
  line-height: 32px;
  padding: 0 16px; }

.mainMenu ul.menu > li:hover > .bt,
.mainMenu ul.menu > li > .bt:hover {
  background-color: #b93a82; }

.mainMenu ul.menu > li > ul {
  /*display: none;*/
  display: block;
  position: absolute;
  width: 100%;
  z-index: 10;
  max-height: 0;
  overflow: hidden;
  background-color: #892b61;
  -webkit-transition: max-height 0.25s, background-color 0.25s;
  -moz-transition: max-height 0.25s, background-color 0.25s;
  -o-transition: max-height 0.25s, background-color 0.25s;
  transition: max-height 0.25s, background-color 0.25s; }

.mainMenu ul.menu > li:hover > ul {
  max-height: 200px;
  background-color: #b93a82; }

.mainMenu ul.menu > li > ul > li > .bt {
  padding: 4px 16px;
  line-height: 1.2em;
  background-color: transparent;
  font-size: 0.85em;
  font-weight: normal; }

.mainMenu ul.menu > li > ul > li > .bt:hover {
  background-color: #cb5c9b; }

.mainMenu {
  background-color: #FFF; }

.mainMenuOverlay {
  display: none;
  background: transparent;
  position: absolute;
  z-index: 1;
  -webkit-transition: background-color 0.25s;
  -moz-transition: background-color 0.25s;
  -o-transition: background-color 0.25s;
  transition: background-color 0.25s; }

.mainMenuOverlay.active {
  display: block;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  background: rgba(255, 255, 255, 0.4);
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
  transition: background-color 0.5s; }

.mainMenuList {
  position: relative;
  z-index: 1; }

.mainMenuList,
.mainMenuList ul,
.mainMenuList li {
  padding: 0;
  margin: 0;
  list-style: none; }

.mainMenuList > li > .bt {
  display: block;
  float: left;
  width: 5%;
  height: 45px;
  background-image: url("../images/bt_home.png");
  background-position: center center;
  background-repeat: no-repeat;
  text-indent: -9999px;
  outline: none; }

.mainMenuList > li > .bt:hover {
  background-color: #EEE; }

.mainMenuList ul.menu {
  display: block;
  float: left;
  width: 95%;
  position: relative; }

.mainMenuList ul > li > ul > .hasSubMenu {
  position: relative; }

.mainMenuList .hasSubMenu > .subMenuToggle {
  position: absolute;
  padding: 0;
  margin: 0;
  font-size: 1.4em;
  background-color: transparent;
  height: 45px;
  line-height: 2.5125rem;
  text-align: center;
  border: none;
  color: #CCC;
  right: 2px;
  -webkit-transition: background-color 0.25s, width 0.25s;
  -moz-transition: background-color 0.25s, width 0.25s;
  -o-transition: background-color 0.25s, width 0.25s;
  transition: background-color 0.25s, width 0.25s;
  width: 0rem;
  overflow: hidden; }

.mainMenuList .hasSubMenu > .subMenuToggle:before {
  content: '»'; }

.mainMenuList ul.menu > li {
  float: left;
  display: block;
  width: 20%; }

.mainMenuList ul.menu > li > .bt {
  display: block;
  height: 45px;
  line-height: 45px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 1em;
  cursor: pointer;
  -webkit-transition: background-color 0.5s;
  -moz-transition: background-color 0.5s;
  -o-transition: background-color 0.5s;
  transition: background-color 0.5s; }

.mainMenuList ul.menu > li > .bt:before {
  content: '';
  display: inline-block;
  width: 19px;
  height: 24px;
  vertical-align: sub;
  margin-right: 4px;
  background-image: url("../images/menu.png");
  background-position: -6px -4px;
  background-repeat: no-repeat; }

.mainMenuList ul.menu > li.m17 > .bt:before {
  background-position: -6px -36px; }

.mainMenuList ul.menu > li.m18 > .bt:before {
  background-position: -6px -68px; }

.mainMenuList ul.menu > li.m19 > .bt:before {
  background-position: -6px -100px; }

.mainMenuList ul.menu > li.m20 > .bt:before {
  background-position: -6px -132px; }

.mainMenuList ul.menu > li.m21 > .bt:before {
  background-position: -6px -164px; }

.mainMenuList ul.menu > li.m17 > ul,
.mainMenuList ul.menu > li.m17.select > .bt,
.mainMenuList ul.menu > li.m17 > .bt:hover {
  background-color: #878400; }

.mainMenuList ul.menu > li.m18 > ul,
.mainMenuList ul.menu > li.m18.select > .bt,
.mainMenuList ul.menu > li.m18 > .bt:hover {
  background-color: #060d3a; }

.mainMenuList ul.menu > li.m19 > ul,
.mainMenuList ul.menu > li.m19.select > .bt,
.mainMenuList ul.menu > li.m19 > .bt:hover {
  background-color: #e3001b; }

.mainMenuList ul.menu > li.m20 > ul,
.mainMenuList ul.menu > li.m20.select > .bt,
.mainMenuList ul.menu > li.m20 > .bt:hover {
  background-color: #fcbd00; }

.mainMenuList ul.menu > li.m21 > ul,
.mainMenuList ul.menu > li.m21.select > .bt,
.mainMenuList ul.menu > li.m21 > .bt:hover {
  background-color: #ff0077; }

.mainMenuList ul.menu > li.select > .bt,
.mainMenuList ul.menu > li > .bt:hover {
  color: #FFF; }

.mainMenuList ul.menu > li.select > .bt:before,
.mainMenuList ul.menu > li > .bt:hover:before {
  background-position: -6px -4px !important; }

.mainMenuList ul.menu > li > ul {
  display: block;
  position: absolute;
  width: 100%;
  background-color: #FFF;
  left: 0;
  z-index: 8;
  max-height: 0;
  overflow: hidden;
  background-color: #FFF;
  -webkit-transition: max-height 0.25s;
  -moz-transition: max-height 0.25s;
  -o-transition: max-height 0.25s;
  transition: max-height 0.25s; }

.mainMenuList ul.menu > li.select > ul {
  padding: 1px;
  max-height: 300px;
  z-index: 9;
  -webkit-transition: max-height 0.5s, background-color 0.5s;
  -moz-transition: max-height 0.5s, background-color 0.5s;
  -o-transition: max-height 0.5s, background-color 0.5s;
  transition: max-height 0.5s, background-color 0.5s; }

.mainMenuList ul.menu > li ul > li {
  display: block;
  float: left;
  width: 33.3333%;
  display: block;
  padding: 2px 0; }

.mainMenuList ul.menu > li ul > li.hidden {
  display: none !important; }

.mainMenuList ul.menu > li ul > li > .bt {
  display: block;
  cursor: pointer;
  margin: 0 2px;
  line-height: 1.3em;
  font-size: 0.9em;
  padding: 4px 12px;
  height: 45px;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  -webkit-align-content: center;
  -moz-align-content: center;
  -ms-align-content: center;
  align-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  color: #FFF;
  background-color: transparent;
  -webkit-transition: background-color 0.25s, margin-right 0.25s, padding 0.25s;
  -moz-transition: background-color 0.25s, margin-right 0.25s, padding 0.25s;
  -o-transition: background-color 0.25s, margin-right 0.25s, padding 0.25s;
  transition: background-color 0.25s, margin-right 0.25s, padding 0.25s;
  position: relative; }

.no-flexbox .mainMenuList ul.menu > li ul > li > .bt {
  display: block; }

.mainMenuList ul.menu > li ul > li:hover > .bt {
  color: #333;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-transition: background-color 0.5s, margin-right 0.5s, padding 0.5s;
  -moz-transition: background-color 0.5s, margin-right 0.5s, padding 0.5s;
  -o-transition: background-color 0.5s, margin-right 0.5s, padding 0.5s;
  transition: background-color 0.5s, margin-right 0.5s, padding 0.5s; }

.mainMenuList ul.menu > li ul > li.hasSubMenu > .bt {
  padding: 4px 34px; }

.touch .mainMenuList ul.menu > li ul > li.hasSubMenu > .bt,
.mainMenuList ul.menu > li ul > li.hasSubMenu:hover > .bt {
  margin-right: 47px;
  padding: 4px 12px; }

.touch .mainMenuList ul.menu > li ul > li > .bt {
  border: solid 1px rgba(255, 255, 255, 0.25); }

.touch .mainMenuList ul.menu > li ul > li > .subMenuToggle {
  width: 45px;
  background-color: rgba(255, 255, 255, 0.25); }

.mainMenuList ul.menu > li ul > li:hover > .subMenuToggle {
  color: #333;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-transition: background-color 0.5s, width 0.5s;
  -moz-transition: background-color 0.5s, width 0.5s;
  -o-transition: background-color 0.5s, width 0.5s;
  transition: background-color 0.5s, width 0.5s;
  width: 45px; }

.mainMenuList ul.menu > li ul > li > .subMenuToggle:hover,
.mainMenuList ul.menu > li ul > li > a.bt:hover {
  color: #333;
  background-color: #FFF; }

.mainMenuList ul.menu > li ul > li:hover > .subMenuToggle {
  border-left: solid 1px rgba(255, 255, 255, 0.25); }

.mainMenuList ul.menu > li ul > li > .bt > span {
  width: 100%;
  overflow: hidden; }

/*///////////////////////////// NIVEAU 3 /////////////////////////////*/
.mainMenuList ul.menu > li ul > li.hasSubMenu.select {
  width: 100%; }

.mainMenuList ul.menu > li ul > li.hasSubMenu.select > .subMenuToggle,
.mainMenuList ul.menu > li ul > li.hasSubMenu.select > .bt {
  display: none; }

.mainMenuList ul.menu > li ul > li.hasSubMenu > ul {
  display: none;
  width: 66.6667%;
  float: right;
  background: rgba(255, 255, 255, 0.25); }

.mainMenuList ul.menu > li ul > li.hasSubMenu > ul.hasOpenSubMenu {
  width: 100%; }

.mainMenuList ul.menu > li ul > li.hasSubMenu > ul.hasOpenSubMenu > li {
  padding: 0; }

.mainMenuList ul.menu > li ul > li.hasSubMenu.select > ul {
  display: block; }

.mainMenuList ul.menu > li ul > li.hasSubMenu > ul > li {
  width: 50%; }

.mainMenuList ul.menu > li ul > li.hasSubMenu > ul > li.subMenuLabel {
  position: absolute;
  left: 0;
  top: 2px;
  bottom: 2px;
  height: auto;
  width: 33.3333%;
  margin: 0;
  background: transparent;
  border-right: solid 2px #FFF;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  -webkit-align-content: center;
  -moz-align-content: center;
  -ms-align-content: center;
  align-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center; }

.mainMenuList ul.menu > li ul > li.hasSubMenu > ul > li.subMenuLabel > label {
  color: #FFF;
  cursor: inherit;
  display: block;
  width: 100%;
  padding-left: 53px;
  padding-right: 8px;
  font-size: 1.1em;
  font-weight: 600;
  line-height: 1.3em;
  text-align: center;
  text-transform: uppercase; }

.mainMenuList ul.menu > li ul > li.hasSubMenu.select > ul > .back {
  position: absolute;
  margin: 0;
  padding: 0;
  left: 0;
  top: 0;
  z-index: 10;
  height: 100%;
  width: 45px; }

.mainMenuList ul.menu > li ul > li.hasSubMenu.select > ul > .back > a {
  line-height: 1.3em;
  padding: 0 0 8px 0;
  width: 100%;
  height: 100%;
  color: #DDD;
  font-size: 2em;
  display: -webkit-flex;
  display: -moz-flex;
  display: -ms-flex;
  display: flex;
  -webkit-align-content: center;
  -moz-align-content: center;
  -ms-align-content: center;
  align-content: center;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-transition: background-color 0.5s, width 0.5s;
  -moz-transition: background-color 0.5s, width 0.5s;
  -o-transition: background-color 0.5s, width 0.5s;
  transition: background-color 0.5s, width 0.5s; }

.mainMenuList ul.menu > li ul > li.hasSubMenu.select > ul > .back > a:before {
  content: '«';
  text-align: center;
  width: 100%; }

.mainMenuList ul.menu > li ul > li.hasSubMenu.select > ul > .back > a:hover {
  background-color: rgba(255, 255, 255, 0.5);
  color: #FFF; }

.main .aside1,
.main .aside2 {
  float: left; }

.main .ariane,
.main .titlePage,
.main .content {
  padding-left: 48px;
  padding-right: 48px;
  float: right; }

.richContent {
  min-height: 400px; }

.titlePage h1,
.richContent h1,
.richContent h2,
.richContent h3,
.richContent h4,
.richContent h5 {
  text-align: left; }

.richContent h2 {
  border-bottom: solid 2px #f18e00; }

.richContent h6 {
  text-align: center;
  margin-left: 50%; }

.richContent img {
  display: inline; }

.richContent img.imgRight {
  float: right;
  margin: 0 0 10px 20px; }

.richContent img.imgLeft {
  float: left;
  margin: 0 20px 10px 0; }

.footer > .row {
  position: relative; }

.footerMenu {
  text-align: right;
  position: initial; }

.footerMenu > ul > li:last-child:after {
  content: ''; }

.pointsys {
  display: block;
  position: absolute;
  right: 0;
  bottom: 8px; }

/*}*/
/*@media only screen and (min-width: 60.063em) {*/
.mainMenu {
  margin-top: -32px; }

.menuRecherche input[type="text"] {
  width: 285px; }

.mainMenuList ul.menu > li > .bt {
  font-size: 1.2em; }

.mainMenuList ul.menu > li ul > li {
  width: 25%; }

.mainMenuList ul.menu > li ul > li.hasSubMenu > ul {
  width: 75%; }

.mainMenuList ul.menu > li ul > li.hasSubMenu > ul > li.subMenuLabel {
  width: 25%; }

.mainMenuList ul.menu > li ul > li.hasSubMenu > ul > li {
  width: 33.3333%; }

/*}*/
/* accueil */
.btFidelite,
.btNewsletter {
  margin-left: 16px; }

.boxArticles {
  padding-left: 32px !important; }
